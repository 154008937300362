.dropdown-menu {
	border-color: white - 20;
	box-shadow: rgba(0, 0, 0, 0.45) 0 0 20px -10px;
	border-radius: 5px;

	.dropdown-item {
		font-size: $default-font-size;

		&.active {
			background: none;
			color: inherit;
		}

		&:focus, &:hover, &:active {
			background-color: $style-color;
			color: black;
		}
	}
}
