@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body.form-membership {
  padding: 3rem 0;
  background: #fff9f2;
  font-family: "Inter";

  .form-group {
      margin-bottom: 1.5rem;
  }

  .mxney-logo {
    width: 80px;
    margin: 0 auto;
  }

  .form-wrapper {
    background-color: white;
    padding: 5rem;
    width: 38.66%;
    margin: 50px auto;

    .logo {
      background-color: white;
      width: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      text-align: center;
      margin-bottom: 2rem;
      margin-top: -100px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
    }

    h5 {
      font-weight: 700 !important;
      font-size: calc(1.3rem + 0.6vw);
      font-family: "Inter";
      margin-bottom: 20px;
    }

    form {
      .form-control {
        border-radius: 0;
      }
    }

    hr {
      margin: 2rem 0;
    }
  }
}
